import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";
import { PageType } from "@app/pkg/router/pageType";
import { PageName } from "@app/pkg/router/pageName";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import {  StringToEntityID } from "@app/entities/uuid";
import { OrganizationUser } from "@app/entities/organzationUser";
import { ErrLoadError } from "@app/entities/errors";
import UserDetailView from "./UserDetailView.vue";
import { UserDetailProps } from "./UserDetailProps";
import { subscriptionsUseCase } from "@app/usecase/subscriptionUseCase";
import { userWorkoutsUseCase } from "@app/usecase/userWorkoutUseCase";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { paymentUseCase } from "@app/usecase/paymentUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { filterUserWorkoutIDs } from "@app/entities/userWorkout";
import { useWorkoutRepo } from "@app/stores/workoutRepo";
import { workoutsUseCase } from "@app/usecase/workoutsUseCase";
import { getPopupRoutes } from "@app/router/popups";

export interface UserUpdateProps {
    oUser: OrganizationUser;
}

export const UserDetailBeforeEnter: NavigationGuardWithThis<undefined> = async (
    to,
    from,
    next,
) => {
    const authView = useAuthViewData();
    const workoutsRepo = useWorkoutRepo();

    console.log(to);

    const oUser = await organizationUserUseCase.getByID(
        StringToEntityID(to.params.organizationUserId as string),
    );

    if (!oUser) {
        throw ErrLoadError;
    }

    const props = getFirsLevelProps<UserDetailProps>();
    props.oUser = oUser;

    const firstStep = await Promise.all([
        subscriptionsUseCase
            .loadByOrganizationUserID(oUser.id)
            .then(async (models) => {
                await subscriptionsUseCase.sync(models);
                return models;
            }),
    ]);

    console.log(firstStep);
    const sIDs = firstStep[0].map((s) => s.id);

    const secondStep = await Promise.all([
        userWorkoutsUseCase.loadBySubscriptionIDs(sIDs),
    ]);

    if (authView.isManager) {
        await paymentUseCase.loadTransactions(
            organizationUseCase.getOrganizationID(),
        );
    }

    const uWorkoutIDs = filterUserWorkoutIDs(secondStep[0]);
    const workoutIDS = workoutsRepo.getMissedIDs(uWorkoutIDs.workoutIDs);
    await workoutsUseCase.loadByIDs(workoutIDS);

    to.meta.breadcrumbs = [
        {
            name: PageName.Users,
            to: PageType.Users,
        },
        {
            name: oUser.user.name,
            to: PageType.UserDetail,
        }
    ];


    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const usersDetailRoute: RouteRecordRaw = {
    path: ":organizationUserId",
    name: PageType.UserDetail,
    component: UserDetailView,
    props: true,
    meta: {
        breadcrumbs: [
            {
                name: PageName.Users,
                to: PageType.Users,
            },
        ],
    },
    children: [
        ...getPopupRoutes(PageType.UserDetail, [])
    ],
    beforeEnter: UserDetailBeforeEnter,
};
