import { ErrForbiddenMessage } from "@app/entities/errors";
import { AppError } from "@app/pkg/error/AppError";
import { PageType } from "@app/pkg/router/pageType";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { getPopupRoutes } from "@app/router/popups";
import { useAuthViewData } from "@app/usecase/authUseCase";
import { organizationUseCase } from "@app/usecase/organizationUseCase";
import { organizationUserUseCase } from "@app/usecase/organizationUserUseCase";
import UsersView from "@users/index/UsersView.vue";
import {
    NavigationGuardWithThis,
    RouteParams,
    RouteRecordRaw,
} from "vue-router";

export const UserIndexBeforeEnter: NavigationGuardWithThis<undefined> = async (
    to,
    from,
    next,
) => {
    const authView = useAuthViewData();
    if (!authView.isManager) {
        throw new AppError({
            message: ErrForbiddenMessage
        });
    }

    await organizationUserUseCase.loadByOrganizationID(
        organizationUseCase.getOrganizationID(),
    );

    // const workoutsRepo = useWorkoutRepo();

    // const firstStep = await Promise.all([
        
    //     subscriptionsUseCase
    //         .loadByOrganizationID(organizationUseCase.getOrganizationID())
    //         .then(async (models) => {
    //             await subscriptionsUseCase.sync(models);
    //             return models;
    //         }),
    // ]);

    // const sIDs = firstStep[1].map((s) => s.id);

    // const secondStep = await Promise.all([
    //     userWorkoutsUseCase.loadBySubscriptionIDs(sIDs),
    // ]);

    // if (authView.isManager) {
    //     await paymentUseCase.loadTransactions(
    //         organizationUseCase.getOrganizationID(),
    //     );
    // }

    // const uWorkoutIDs = filterUserWorkoutIDs(secondStep[0]);
    // const workoutIDS = workoutsRepo.getMissedIDs(uWorkoutIDs.workoutIDs);
    // await workoutsUseCase.loadByIDs(workoutIDS);

    const props = getFirsLevelProps();
    // didn't find a better way
    to.params = {
        ...to.params,
        ...(props as unknown as RouteParams),
    };
    next();
};

export const usersIndexRoute: RouteRecordRaw = {
    path: "/users",
    name: PageType.Users,
    component: UsersView,
    beforeEnter: UserIndexBeforeEnter,
    props: getFirsLevelProps(),
    children: [...getPopupRoutes(PageType.Users, [])],
};
