export const enum PageType {
    OrganizationInfo = "OrganizationInfo",
    OrganizationEdit = "OrganizationEdit",
    OrganizationStatistic = "OrganizationStatistic",
    Disciplines = "Disciplines",
    DisciplineAdd = "DisciplineAdd",
    DisciplineUpdate = "DisciplineUpdate",
    Locations = "Locations",
    LocationAdd = "LocationAdd",
    LocationUpdate = "LocationUpdate",
    SubscriptionTypes = "SubscriptionTypes",
    SubscriptionTypesAdd = "SubscriptionTypesAdd",
    SubscriptionTypesUpdate = "SubscriptionTypesUpdate",
    Users = "Users",
    UserAdd = "UserAdd",
    UserDetail = "UserDetail",
    UserUpdate = "UserUpdate",
    UserSubscriptionAdd = "UserSubscriptionAdd",
    UserWorkouts = "UserWorkouts",
    WorkoutsList = "WorkoutsList",
    Workouts = "Workouts",
    WorkoutsDetailed = "WorkoutsDetailed",
    UserSubscriptions = "UserSubscriptions",
    Schedule = "Schedule",
    Welcome = "Welcome",
    Index = "Index",
    SchedulePlan = "SchedulePlan",
    ScheduleAdd = "ScheduleAdd",
    ScheduleUpdate = "ScheduleUpdate",
    WorkoutAdd = "WorkoutAdd",
    UserWorkoutAdd = "UserWorkoutAdd",
    UserWorkoutUpdate = "UserWorkoutUpdate",
    WorkoutUpdate = "WorkoutUpdate",
    UserSubscriptionUpdate = "UserSubscriptionUpdate",
    Login = "Login",
    Logout = "Logout",
    Personal = "Personal",
    Payments = "Payments",
    SignUp = "SignUp",
    AccountAdd = "AccountAdd",
}
