<template>
  <page-component
    :is-loading="isLoading"
    :links="actionLinks"
    title="Users"
  >
    <div class="overflow-x-auto">
      <table class="table table-zebra">
        <tbody>
          <tr
            v-for="oUser in allUsers"
            :key="EntityIDToString(oUser.id)"
          >
            <td>
              <router-link
                :to="{
                  path: '/users/' + oUser.id.value,
                }"
              >
                <b class="pr-2">{{ oUserName(oUser) }}</b>
              </router-link>
            </td>
            <td>
              <span class="app-badge app-badge-role pr-2">{{
                oUser.user.isAdmin
                  ? t("Administrator")
                  : t(UserRoleToString(oUser.role))
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { oUserName } from "@app/entities/organzationUser";
import { EntityIDToString } from "@app/entities/uuid";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { useOrganizationUserViewData } from "@app/usecase/organizationUserUseCase";
import { UserRoleToString } from "@app/views/converters/userRole";
import { Link, LinkIcons } from "@app/views/models/links";
import { computed, ComputedRef, ref } from "vue";
import { useI18n } from "vue-i18n";


const organizationUserView = useOrganizationUserViewData();
const { t } = useI18n();

const isLoading = ref<boolean>(false);

const allUsers = organizationUserView.All;


const actionLinks: ComputedRef<Link[]> = computed(() => [
  {
    text: t("Add new user"),
    image: LinkIcons.add,
    to: {
      name: "UserAdd",
    },
    disabled: !authUseCase.canEdit(ENTITIES_LIST.ORGANIZATION_USER),
  },
]);
</script>

<style scoped></style>
