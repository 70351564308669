import { RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";
import { UserIndexBeforeEnter } from "@users/index/route";
import { usersAddRoute } from "@users/add/route";
import { usersUpdateRoute } from "@users/update/route";
import { EntityIDToString, OrganizationUserID } from "@app/entities/uuid";
import { Link, LinkIcons } from "@app/views/models/links";
import { getPopupRoutes, pupUpRoute } from "@app/router/popups";
import { PageType } from "@app/pkg/router/pageType";
import UsersView from "./index/UsersView.vue";
import { getFirsLevelProps } from "@app/pkg/router/props";
import { usersDetailRoute } from "./detail/route";
import { PageName } from "@app/pkg/router/pageName";


export const usersRoutes: RouteRecordRaw[] = [
    {
        path: "/users",
        meta: {
            breadcrumbs: [
                {
                    name: PageName.Users,
                    to: PageType.Users,
                },
            ],
        },
        children: [
            {
                path: "",
                name: PageType.Users,
                component: UsersView,
                beforeEnter: UserIndexBeforeEnter,
                props: getFirsLevelProps(),
            },
            usersDetailRoute,
            usersAddRoute,
            usersUpdateRoute,
            ...getPopupRoutes(PageType.Users, [])
        ],
    },
    
];

export const getUserUserDetailLink = (
    route: RouteLocationNormalizedLoaded,
    text: string,
    userID: OrganizationUserID,
): Link => {
    return {
        image: LinkIcons.user,
        text: text,
        to: pupUpRoute(
            route,
            PageType.UserDetail,
            {},
            {
                organizationUserId: EntityIDToString(userID),
            },
        ),
        disabled: false,
    };
};