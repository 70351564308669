<template>
  <page-component
    :title="props.oUser.user.name"
    :links="links"
  >
    <SubscriptionComponent
      v-for="subscription in subscriptions"
      :key="EntityIDToString(subscription.id)"
      :model-id="subscription.id"
    />
  </page-component>
</template>

<script lang="ts" setup>
import PageComponent from "@app/components/PageComponent.vue";
import { UserDetailProps } from "./UserDetailProps";
import { useSubscriptionsViewData } from "@app/usecase/subscriptionUseCase";
import { EntityIDToString } from "@app/entities/uuid";

import SubscriptionComponent from "@app/views/entityComponents/SubscriptionComponent.vue";
import { UserDetailBeforeEnter } from "./route";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { authUseCase, ENTITIES_LIST } from "@app/usecase/authUseCase";
import { Link, LinkIcons } from "@app/views/models/links";
import { pupUpRoute } from "@app/router/popups";
import { useI18n } from "vue-i18n";
import { PageType } from "@app/pkg/router/pageType";
import { computed } from "vue";


const props = defineProps<UserDetailProps>();
const subscriptionsView = useSubscriptionsViewData()
const route = useRoute();
const { t } = useI18n();


const subscriptions = computed(() => subscriptionsView.getByOrganizationUserID(
  props.oUser.id,
))

const links = computed(() => {
  const links: Link[] = [];

  links.push({
    image: LinkIcons.add,
    text: t("Add subscription"),
    to: pupUpRoute(
      route,
      PageType.UserSubscriptionAdd,
      {},
      {},
    ),
    disabled: !authUseCase.canEdit(ENTITIES_LIST.USER_WORKOUT),
  });

  return links;
});

onBeforeRouteUpdate(UserDetailBeforeEnter);
</script>

<style scoped></style>
